<template>
	<b-row>
		<b-col cols="12">
			<!-- 1 -->
			<b-card no-body class="mb-4">
				<!-- Search Control -->
				<b-card-body class="p-3 d-flex justify-content-between">
					<b-form-group class="mb-2 w-25">
						<b-input-group>
							<b-form-input id="filter-input" v-model="filter" type="search" placeholder="Type to Search"></b-form-input>

							<b-input-group-append>
								<b-button variant="danger" :disabled="!filter" @click="filter = ''">Clear</b-button>
							</b-input-group-append>
						</b-input-group>
					</b-form-group>
					<div class="d-flex align-items-center">
						<b-button variant="primary" class="mr-2" v-b-modal.modal-documentType @click="titleModal = 'CREATE'">Add</b-button>
					</div>
				</b-card-body>
				<!-- End Section -->

				<!-- Data Table -->
				<b-table
					style="min-height: 250px;"
					show-empty
					responsive
					small
					class="mb-0"
					head-variant="light"
					:items="documentsTypes"
					:filter="filter"
					:fields="fields"
					hover
				>
					<template #cell(id)="data"> #{{ data.item.id }} </template>
					<template #cell(isActive)="data">
						<b-badge variant="success" v-if="data.item.isActive">Active</b-badge>
						<b-badge variant="danger" v-else>Inactive</b-badge>
					</template>
					<template #cell(action)="data">
						<b-dropdown variant="primary" id="dropdown-1" text="Actions" class="">
							<b-dropdown-item v-b-modal.modal-documentType @click="editDocumentsTypes(data.item)">Edit</b-dropdown-item>
							<!-- <b-dropdown-item v-b-modal.modal-remove>Delete</b-dropdown-item> -->
						</b-dropdown>
					</template>
				</b-table>
				<!-- End Section -->

				<!-- Pagination -->
				<div class="p-3 d-flex align-items-center">
					<b-form-group
						label="Per page"
						label-for="per-page-select"
						label-cols-sm="6"
						label-cols-md="4"
						label-cols-lg="9"
						label-size="sm"
						label-class="fw-medium"
						class="mb-0"
					>
						<b-form-select @change="getDocumentsTypes" id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
					</b-form-group>
					<div class="ml-auto">
						<b-pagination
							@input="getDocumentsTypes"
							v-model="currentPage"
							:total-rows="totalDocumentsTypes"
							:per-page="perPage"
							align="fill"
							class="my-0"
						></b-pagination>
					</div>
				</div>
				<!-- End Section -->
			</b-card>
		</b-col>

		<!-- Modal -->
		<b-modal id="modal-documentType" hide-footer centered :title="titleModal" @close="closeModal">
			<b-form @submit.prevent="createDocumentType">
				<b-form-group label="Document" label-class="fw-medium mb-1" label-for="description">
					<b-form-input id="description" type="text" placeholder="Document type here" required v-model="description" autocomplete="off"></b-form-input>
				</b-form-group>

				<b-button block :disabled="loadingDocumentType" variant="primary" type="submit">
					<b-spinner small v-if="loadingDocumentType"></b-spinner>
					<span v-else>Save</span>
				</b-button>
			</b-form>
		</b-modal>

		<b-modal id="modal-remove" size="sm" hide-footer centered title="Delete document">
			<b-card-text>
				Are you sure to want to remove this document?
			</b-card-text>
			<div class="d-flex justify-content-end">
				<!-- <b-button variant="danger" class="mr-2" @click="removeRoles">Yes</b-button> -->
				<b-button variant="danger" class="mr-2">Yes</b-button>
				<!-- <b-button variant="secondary" @click="$bvModal.hide('modal-remove')">No</b-button> -->
				<b-button variant="secondary">No</b-button>
			</div>
		</b-modal>
		<!-- End Section -->
	</b-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "documents",

	data: () => ({
		page: {
			title: "documents",
		},
		filter: null,
		fields: [
			{
				key: "id",
				label: "No",
				sortable: true,
			},
			{
				key: "description",
				label: "Document type",
				sortable: true,
			},
			{
				key: "action",
				label: "Actions",
				sortable: false,
			},
		],

		totalRows: 1,
		currentPage: 1,
		perPage: 10,
		pageOptions: [5, 10, 20, 40, { value: 100, text: "Show a lot" }],
		titleModal: "Create Document",
		loadingDocumentType: false,
		description: "",
		documentTypeSelected: {},
	}),
	components: {},
	methods: {
		clearValues() {
			this.description = "";
			this.documentTypeSelected = {};
		},
		closeModal() {
			this.isEditing = false;
			this.clearValues();
			this.titleModal = "Create Document";
		},
		async editDocumentsTypes(documentsTypes) {
			const documentsTypeData = await this.$store.dispatch("documentsTypes/get_documentsTypes", documentsTypes.id);
			this.isEditing = true;
			this.documentTypeSelected = documentsTypeData;
			this.description = documentsTypeData.description;
			this.titleModal = "EDIT";
		},
		async getData() {
			await this.getDocumentsTypes();
			await this.$store.dispatch("authorizations/get_allAuthorizations");
		},
		async getDocumentsTypes() {
			let data = {
				skip: this.currentPage,
				take: this.perPage,
			};
			await this.$store.dispatch("documentsTypes/get_allDocumentsTypes", data);
		},
		async createDocumentType() {
			this.loadingDocumentType = true;
			let data = {
				description: this.description,
			};

			if (this.isEditing) {
				(data.id = this.documentTypeSelected.id), await this.$store.dispatch("documentsTypes/update_documentsTypes", data);
				this.clearValues();
			} else {
				await this.$store.dispatch("documentsTypes/create_documentsTypes", data);
			}

			this.clearValues();
			await this.getDocumentsTypes();
			this.$bvModal.hide("modal-documentType");
			this.loadingDocumentType = false;
		},
	},
	computed: {
		...mapGetters({
			documentsTypes: "documentsTypes/getDocumentsTypes",
			totalDocumentsTypes: "documentsTypes/getTotalTypes",
			authorizations: "authorizations/getAuthorizations",
		}),
	},
	mounted() {},
	created() {
		this.getData();
	},
};
</script>
